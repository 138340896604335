// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-exotics-js": () => import("./../../../src/pages/exotics.js" /* webpackChunkName: "component---src-pages-exotics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-novelties-js": () => import("./../../../src/pages/novelties.js" /* webpackChunkName: "component---src-pages-novelties-js" */),
  "component---src-pages-shearlings-js": () => import("./../../../src/pages/shearlings.js" /* webpackChunkName: "component---src-pages-shearlings-js" */),
  "component---src-pages-showroom-js": () => import("./../../../src/pages/showroom.js" /* webpackChunkName: "component---src-pages-showroom-js" */),
  "component---src-pages-zoom-js": () => import("./../../../src/pages/zoom.js" /* webpackChunkName: "component---src-pages-zoom-js" */)
}

